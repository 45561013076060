<template>
 <div id="page">
    <header-bar zzz-show-back-btn="false" />
  <div class="page-content header-clear-medium">
    <div class="card card-style">
      <div class="content mb-0">
        <h1 class="text-center">
          <i class="fa fa-question-circle fa-3x color-highlight mt-3"></i>
        </h1>
        <h1 class="pt-3 mt-3 text-center font-900 font-40 text-uppercase">Forgot</h1>
        <p class="text-center color-highlight font-11">Let's get you back into your account</p>

        <div class="input-style has-icon input-style-1 input-required">
          <i class="input-icon fa fa-at color-theme"></i>
          <span>Email</span>
          <em>(required)</em>
          <input type="email" placeholder="Email" />
        </div>
        <a
          href="#"
          class="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900"
        >Send Reset Instructions</a>
      </div>
    </div>

    <div class="footer card card-style">
      <a href="#" class="footer-title">
        <span class="color-highlight">StickyMobile</span>
      </a>
      <p class="footer-text">
        <span>
          Made with
          <i class="fa fa-heart color-highlight font-16 pl-2 pr-2"></i> by Enabled
        </span>
        <br />
        <br />Powered by the best Mobile Website Developer on Envato Market. Elite Quality. Elite Products.
      </p>
      <div class="text-center mb-3">
        <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-facebook">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-twitter">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-phone">
          <i class="fa fa-phone"></i>
        </a>
        <a
          href="#"
          data-menu="menu-share"
          class="icon icon-xs rounded-sm mr-1 shadow-l bg-red2-dark"
        >
          <i class="fa fa-share-alt"></i>
        </a>
        <a href="#" class="back-to-top icon icon-xs rounded-sm shadow-l bg-dark1-light">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
      <p class="footer-copyright">
        Copyright &copy; Enabled
        <span id="copyright-year">2017</span>. All Rights Reserved.
      </p>
      <p class="footer-links">
        <a href="#" class="color-highlight">Privacy Policy</a> |
        <a href="#" class="color-highlight">Terms and Conditions</a> |
        <a href="#" class="back-to-top color-highlight">Back to Top</a>
      </p>
      <div class="clear"></div>
    </div>
  </div>
 </div>
</template>

<script lang="ts">
// import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import HeaderBar from "@/components/header-bar.vue";
import { Options, Vue } from 'vue-class-component';
// @ is an alias to /src
@Options({
  components: {HeaderBar},
})
export default class ForgotView extends Vue {}
</script>
