
// import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import HeaderBar from "@/components/header-bar.vue";
import { Options, Vue } from 'vue-class-component';
// @ is an alias to /src
@Options({
  components: {HeaderBar},
})
export default class ForgotView extends Vue {}
