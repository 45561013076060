<template>
  <div id="page">
    <Header-Bar></Header-Bar>
    <div class="page-content header-clear-medium">
      <div class="card card-style">
        <div class="content mb-0">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">Register</h1>
          <p class="text-center color-highlight font-11">Don't have an account? Register here.</p>
          <form id="registerForm">
            <!-- <div class="input-style has-icon input-style-1 input-required">
              <i class="input-icon fa fa-user color-theme"></i>
              <span>Username</span>
              <em>(required)</em>
              <input type="name" placeholder="Username" />
            </div>
            <div class="input-style has-icon input-style-1 input-required">
              <i class="input-icon fa fa-at color-theme"></i>
              <span>Email</span>
              <em>(required)</em>
              <input type="email" placeholder="Email" />
            </div>
            <div class="input-style has-icon input-style-1 input-required">
              <i class="input-icon fa fa-lock color-theme"></i>
              <span>Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Choose a Password" />
            </div>
            <div class="input-style has-icon input-style-1 input-required mb-4">
              <i class="input-icon fa fa-lock color-theme"></i>
              <span>Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Confirm your Password" />
            </div>

            <a
              class="btn btn-m btn-full rounded-s shadow-l bg-green1-dark text-uppercase font-900"
            >Create account</a> -->
          </form>
          <div class="divider"></div>

          <p class="text-center">
            <router-link
              to="/account/login"
              class="color-theme opacity-50 font-12"
            >Already Registered? Sign in Here</router-link>
          </p>

          <a
            href="#"
            class="btn btn-icon btn-m btn-full rounded-s shadow-l bg-facebook text-uppercase font-900 text-left"
          >
            <i class="text-center">
              <fa :icon="['fab','facebook-f']"></fa>
            </i>Register with Facebook
          </a>
          <a
            href="#"
            class="btn btn-icon btn-m mt-2 mb-4 btn-full rounded-s shadow-l bg-twitter text-uppercase font-900 text-left"
          >
            <i class="text-center">
              <fa :icon="['fab','twitter']"></fa>
            </i>Register with Twitter
          </a>
        </div>
      </div>
    </div>
    <All-Menus></All-Menus>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import { Options, Vue } from 'vue-class-component';
import HeaderBar from "@/components/header-bar.vue";
import AllMenus from "@/components/all-menus.vue";
// @ is an alias to /src
@Options({
  components: { HeaderBar, AllMenus },
})
export default class RegisterView extends Vue {
  public async mounted(){
     //because we are hosted on same domain we are cheating and pulling the
      const resp =await $.get('/account/register').promise();
      const loginForm = $(resp).find('#registerForm');
      $(this.$el).find('#registerForm').replaceWith(loginForm);
      window.ww.theme.initAll();
  }
}
</script>
<style lang="scss">
@import "./src/scss/components/_buttons.scss";
</style>