
import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import { Options, Vue } from 'vue-class-component';
import HeaderBar from "@/components/header-bar.vue";
import AllMenus from "@/components/all-menus.vue";
// @ is an alias to /src
@Options({
  components: { HeaderBar, AllMenus },
})
export default class RegisterView extends Vue {
  public async mounted(){
     //because we are hosted on same domain we are cheating and pulling the
      const resp =await $.get('/account/register').promise();
      const loginForm = $(resp).find('#registerForm');
      $(this.$el).find('#registerForm').replaceWith(loginForm);
      window.ww.theme.initAll();
  }
}
