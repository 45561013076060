<template>
  <sticky-page>
    <div class="card card-style" v-if="verificationToken">
      <div class="content mt-4 mb-0">
        <h1 class="text-center font-900 font-40 text-uppercase mb-0">Login</h1>
        <p class="bottom-0 text-center color-highlight font-11">
          Let's get you logged in
        </p>
        <form :action="externalAction" method="post">
          <button
            type="submit"
            class="btn btn-icon btn-m btn-full btn-block shadow-l bg-facebook text-uppercase font-900 text-left"
            name="provider"
            value="Facebook"
          >
            <i class="text-center"><fa :icon="['fab', 'facebook-f']" /></i
            >Continue with Facebook
          </button>
          <button
            type="submit"
            class="btn btn-icon btn-m mt-2 btn-full btn-block shadow-l bg-google text-uppercase font-900 text-left"
            name="provider"
            value="Google"
          >
            <i class="text-center"><fa :icon="['fab', 'google']" /></i>Continue
            with Google
          </button>
          <input
            name="__RequestVerificationToken"
            type="hidden"
            :value="verificationToken"
          />
        </form>
        <div class="divider"></div>
        <form :action="passwordAction" method="post">
          <div class="input-style has-icon input-style-1 input-required pb-1">
            <i class="input-icon fa fa-user color-theme"></i>
            <span>Username</span>
            <em>(required)</em>
            <input name="email" type="text" placeholder="Username" />
          </div>
          <div class="input-style has-icon input-style-1 input-required pb-1">
            <i class="input-icon fa fa-lock color-theme"></i>
            <span>Password</span>
            <em>(required)</em>
            <input name="password" type="password" placeholder="Password" />
          </div>
          <input
            name="__RequestVerificationToken"
            type="hidden"
            :value="verificationToken"
          />
          <button
            type="submit"
            class="btn btn-m mt-2 mb-4 btn-full bg-green1-dark text-uppercase font-900"
          >
            Login
          </button>
        </form>
        <div class="divider mt-4 mb-3"></div>

        <div class="d-flex">
          <div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-left">
            <a href="#" class="color-theme">Create Account</a>
          </div>
          <div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-right">
            <a href="#" class="color-theme">Forgot Credentials</a>
          </div>
        </div>
      </div>
    </div>
  </sticky-page>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SettingsFactory } from "@/shared/config";
import { LogService } from "@/services/logService";
import StickyPage from "@/components/sticky-page.vue";
import UserService from "@/services/userService";
// @ is an alias to /src
@Options({
  name: "SignInView",
  components: {
    StickyPage,
  },
})
export default class SignInView extends Vue {
  private ls = new LogService();
  private us = UserService;
  private config = new SettingsFactory().getSettings();
  private externalAction =
    this.config.externalLoginPath + encodeURIComponent("/signedin.html");
  private passwordAction =
    this.config.loginPath + encodeURIComponent("/signedin.html");
  public get verificationToken() {
    return this.us.requestVerificationToken?.token;
  }

  public async created() {

    setTimeout(() => {
      if (!this.verificationToken) {
        this.$app.snack({ msg: "Signin currently unavilable", type: "error" });
      }
    }, this.config.httpTimeout); //TODO: timeout val
  }
}
</script>