
import { Options, Vue } from "vue-class-component";
import { SettingsFactory } from "@/shared/config";
import { LogService } from "@/services/logService";
import StickyPage from "@/components/sticky-page.vue";
import UserService from "@/services/userService";
// @ is an alias to /src
@Options({
  name: "SignInView",
  components: {
    StickyPage,
  },
})
export default class SignInView extends Vue {
  private ls = new LogService();
  private us = UserService;
  private config = new SettingsFactory().getSettings();
  private externalAction =
    this.config.externalLoginPath + encodeURIComponent("/signedin.html");
  private passwordAction =
    this.config.loginPath + encodeURIComponent("/signedin.html");
  public get verificationToken() {
    return this.us.requestVerificationToken?.token;
  }

  public async created() {

    setTimeout(() => {
      if (!this.verificationToken) {
        this.$app.snack({ msg: "Signin currently unavilable", type: "error" });
      }
    }, this.config.httpTimeout); //TODO: timeout val
  }
}
